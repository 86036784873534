import Vue from 'vue'
import Vuex from 'vuex'
import Styles from './data/Styles'
import HELPERS from './data/helpers'
// import WAIVERS from '@/store/data/Waivers';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // waivers: WAIVERS,
    schedule: HELPERS.groups,
    days: HELPERS.days,
    regInfo: HELPERS.regInfo,
    styles: Styles,
    aboutSlides: [{
      header: 'Owners',
      contents: [{
        primary: 'owners'
      }]
    }, {
      header: 'Teachers',
      contents: [{
        primary: 'bw-contemporary'
      }, {
        primary: 'hiphop'
      }, {
        primary: 'bw-jazzfunk'
      }, {
        primary: 'jazz'
      }, {
        primary: 'bw-tumbling'
      }, {
        primary: 'ballet'
      }]
    }],
    contactSlides: [{
      header: 'Owners',
      contents: [{
        primary: 'owners'
      }]
    }, {
      header: 'Teachers',
      contents: [{
        primary: 'bw-hiphop'
      }, {
        primary: 'jazzfunk'
      }, {
        primary: 'bw-jazz'
      }, {
        primary: 'tumbling'
      }, {
        primary: 'bw-ballet'
      }, {
        primary: 'contemporary'
      }]
    }]
  },
  getters: {
    days: ({ days }) => Object.entries(days)
      .map(([k, v]) => ({ k, v }))
  },

  mutations: {
  },

  actions: {
  },

  modules: {
  }
})
