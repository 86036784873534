export default [{
  policy: 'Registration Fee',
  info: {
    text: `The first month's tuition is required with the registration
      form to reserve class space. Registration will not be considered
      complete and class space is not reserved until this fee has been
      paid. The annual registration fee is $25 per dancer.`
  }
}, {
  policy: 'Class Fees',
  info: {
    points: [
      `Tuition will be posted on you dancers account on the 25th. Class
        fees are due by the 1st of each month. Class changes and
        cancellations must be made before the 25th`,
      `A $10 late fee will be applied to accounts not paid by the 5th.
        Dancers will not be allowed to enter class if their account has
        a balance on the second week of the month.`,
      `Tuition may be paid by the following methods: cash, money order,
        check, or credit card (We accept MasterCard, Visa, and Discover).`,
      `Checks should be made payable to Catch The Beat Dance Academy
        and include your child’s full name in the memo.`,
      `There is a $10 returned check fee, per check, on all returned
        checks.`,
      `Tuition remains the same regardless of absences and whether it
        is a long (5-weeks) month or a short (3-weeks) month!`
    ]
  }
}, {
  policy: 'Enrollment Changes',
  info: {
    text: `If you need to drop or add a class at any time during the
    year, you will need to fill out a drop-add form. Not showing up
    for classes, does not constitute a withdrawal from class. You will
    continue to be charged for tuition until the drop-add form has
    been completed. Catch The Beat Dance Academy does not carry
    medical insurance for its students. It is required that all
    students be covered by their own family insurance policies and if
    injury occurs it is understood that the student's ownpolicy is
    your only source of reimbursement.`,
    points: []
  }
}, {
  policy: 'Bad Wather / Holidays',
  info: {
    text: `The studio will not necessarily close for snow days,
    regardless if area schools are closed, since many times roads are
    clear and safe by 3:00 p.m. If in doubt call the studio after 12
    noon on the given day. If classes are canceled you will be contacted
    and it will also be posted on our social media pages.`
  }
}, {
  policy: 'Attendance',
  info: {
    text: `Good attendance is imperative, as absences and tardiness can
      hold back an entire class. Students should be prompt and prepared
      for class.`
  }
}, {
  policy: 'Practice Wear',
  info: {
    text: `Dance Wear is required for all classes. Students must wear
      comfortable form fitting dance attire to display proper body
      alignment and allow for easy movement. No baggy t-shirts, baggy
      shorts, blue jeans, no jewelry or other inappropriate attire
      will be allowed. Hair should always be securely pulled away from
      the face. Please make sure your dancers hair is in a ponytail or
      bun before they enter the dance room.`,
    points: [
      `Ballet - Black leotard, ballet tights and pink ballet shoes.
        Skirts, wraps, tutus or shorts over tights are allowed but we
        must always be able to see the knees and ankles - NO DANCE
        PANTS PLEASE!`,
      `Jazz - Any type of form-fitting appropriate dance wear such as
        jazz pants, leotards, sports bras or form-fitting t-shirt and
        black jazz shoes.`,
      `Contemporary - Form-fitting, comfortable attire and
        turners/lyrical shoes.`,
      'Hip Hop - Tennis shoes and comfortable clothing.'
    ]
  }
}, {
  policy: 'Studio Rules',
  info: {
    points: [
      `No student should be in the studio unless enrolled in that
        particular class.`,
      `Students should be dressed and prepared for class before it
        starts.`,
      `No street shoes should be worn in the studios. Please change
        into dance shoes before class.`,
      `No food or drinks are allowed in the dance studios. Students
        may bring water.`,
      `Parents should not leave young students unattended in the
        waiting area before or after class.`,
      `Parents and siblings are not allowed in the dance room without
        permission from the dance instructor. PLEASE DO NOT DISTURB
        CLASS!`,
      'Chewing gum is NEVER allowed in the dance studio.',
      `Dancers and parents should always treat our employees and
        students with respect. Instructors have the right to send
        students out of the dance room if they are being rude or
        disruptive. CTB has the right to refuse service to individuals
        who do not abide by studio standards.`,
      `CTB is not responsible for any lost personal items. Items left
        at the studio will be placed in the lost and found and if not
        claimed within 30 days will be donated to charity.`
    ]
  }
}]
