import SCHEDULE from './Schedule';
import REGISTRATION from './Registration';

export default Object.assign({
  get days (): {[k in DayCode]: string} {
    return {
      m: 'Monday',
      t: 'Tuesday',
      w: 'Wednesday',
      th: 'Thursday',
      f: 'Friday',
      s: 'Saturday'
    }
  }
},
SCHEDULE,
REGISTRATION);
