import PolicyData from '../InfoPolicies';

export default {
  get regInfo () {
    return {
      student: '',
      guardian: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      pNum: '',
      altNum: '',
      ecName: '',
      ecRelation: '',
      ecNumber: '',
      dob: '',
      school: '',
      grade: '',
      medical: '',
      classes: [],
      mdRelease: `In the event you are unable to reach me,
      in case of accident or injury, I give
      my permission for treatment as deemed
      necessary by staff or emergency personnel.
      This registration for my child releases Catch The
      Beat Dance Academy and its staff of liability in
      case of injury or accident incurred and acts as my
      formal agreance to CTB Dance's official waiver and
      release form which I have reviewed.`,
      policyDisclaimer: `I have read all studio information and policies
      including monthly fees, insurance, bad weather/holiday
      policies, attendance, class observation and practice wear.
      My child and I fully understand and agree to abide by these policies.`,
      policies: PolicyData
    }
  }
}
