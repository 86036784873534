
import Vue from 'vue'
import StylesList from '@/components/StyleList.vue'
import PopupImage from '@/components/PopupImage.vue'

export default Vue.extend({
  name: 'Overview',

  components: {
    StylesList,
    PopupImage
  },

  data: () => ({})
})
