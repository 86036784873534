
import Vue from 'vue'
export default Vue.extend({
  data: () => ({}),

  computed: {
    styles () {
      return this.$store.state.styles;
    }
  }
})
