<template>
  <div class="home">
    <overview msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Overview from '@/components/Overview.vue'

export default {
  name: 'Home',
  components: {
    Overview
  }
}
</script>
