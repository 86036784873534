export default [{
  name: 'Ballet',
  src: '/imgs/styles/ballet.png',
  def: `a highly technical form of dance with it's own vocabulary
    based on french terminology. It is a classical dance form
    demanding grace and precision.`
}, {
  name: 'Jazz',
  src: '/imgs/styles/jazz.png',
  def: `a form of dancing which involves usage of smooth dance
    movements and postures to the beats. This style emphasizes the
    energy and presentaion of the performer.`
}, {
  name: 'Hip Hop',
  src: '/imgs/styles/hip-hop.png',
  def: `refers to street dance styles primarily performed to hip hop
    music or that have evolved as part of hip hop culture. It includes
    a wide range of styles especially known for breaking.`
}, {
  name: 'Pom',
  src: '/imgs/styles/pom.png',
  def: `incorporates multiple dance styles and technical work using
    pom-poms in all dance routines. They are not considered
    cheerleaders because they do not chant or do stunts, they simply
    dance for entertainment.`
}, {
  name: 'Contemporary',
  src: '/imgs/styles/contemporary.png',
  def: `expressive dance that combines several
    dance genres including modern, jazz, lyrical and ballet connecting
    the mind and body through fluid dance movements.`
}, {
  name: 'Jazz Funk',
  src: '/imgs/styles/jazz-funk.png',
  def: `a diva-like dance style. It takes elements from
    both hip hop and jazz and gives you a chance to release sassiness
    and dance fiercely.`
}]
