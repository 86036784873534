import Vue from 'vue'
import Construct from 'vuetify/lib'

Vue.use(Construct)

export default new Construct({
  theme: {
    dark: true
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})
