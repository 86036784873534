export default {
  get groups () {
    return [{
      name: 'Preschool',
      req: 'Ages 3-5',
      desc: 'Ages 3-5',
      classes: {
        s: [{
          title: 'Ballet',
          code: 'ballet_0-1',
          start: '11:00',
          end: '11:30am'
        }, {
          title: 'Dancing tots',
          code: 'ballet_0-1',
          start: '11:30am',
          end: '12:00'
        }]
      }
    }, {
      name: 'Beginner',
      req: 'Ages 5+',
      desc: 'Ages 5+',
      classes: {
        m: [{
          title: 'Lyrical',
          code: 'lyrical_1',
          start: '5:00',
          end: '5:45'
        }, {
          title: 'Tumbling',
          code: 'acro_1',
          start: '6:15',
          end: '7:00'
        }],
        t: [{
          title: 'Hip Hop',
          code: 'hiphop_1',
          start: '6:30',
          end: '7:15'
        }],
        w: [{
          title: 'Ballet 1',
          start: '5:00',
          end: '5:30'
        }, {
          title: 'Ballet 2',
          start: '6:30',
          end: '7:15'
        }],
        th: [{
          title: 'Jazz',
          code: 'jazz_1',
          start: '5:15',
          end: '6:00'
        }],
        f: [{
          title: 'Tap',
          code: 'tap_1',
          start: '5:00',
          end: '5:30'
        }, {
          title: 'Jazz Funk',
          code: 'jazz_funk_1',
          start: '5:30',
          end: '6:15'
        }, {
          title: 'Musical Theater',
          code: 'theater_1',
          start: '6:15',
          end: '7:45'
        }, {
          title: 'Pom',
          code: 'pom_1',
          start: '6:45',
          end: '7:15'
        }],
        s: [{
          title: 'Contemporary 1',
          code: 'cont_1',
          start: '12:00',
          end: '12:45'
        }]
      }
    }, {
      name: 'Intermediate',
      req: 'Teacher placement',
      classes: {
        m: [],
        t: [{
          title: 'Hip Hop',
          code: 'hiphop_2',
          start: '5:45',
          end: '6:30'
        }],
        w: [{
          title: 'Ballet 3',
          code: 'ballet_3',
          start: '7:15',
          end: '8:00'
        }, {
          title: 'Ballet 4',
          code: 'ballet_4',
          start: '5:30',
          end: '6:30'
        }],
        th: [{
          title: 'Jazz 2',
          code: 'jazz_2',
          start: '7:00',
          end: '8:00'
        }],
        s: [{
          title: 'Contemporary 2',
          code: 'cont_2',
          start: '1:15',
          end: '2:00'
        }]
      }
    }, {
      name: 'Adult',
      desc: 'Ages 18+',
      classes: {
        m: [{
          title: 'Belly dancing',
          start: '7:30',
          end: '8:30'
        }, {
          title: '(And Sunday)',
          start: '4:00',
          end: '5:00'
        }],
        t: [{
          title: 'Hip Hop',
          start: '7:15',
          end: '8:00'
        }],
        f: [{
          title: 'Sassy Mama',
          start: '7:15',
          end: '8:00'
        }]
      }
    }, {
      name: 'Other',
      classes: {
        m: [{
          title: 'Stretch & conditioning',
          code: 'stretch_condition',
          start: '7:00',
          end: '7:30'
        }],
        t: [],
        w: [],
        th: [{
          title: 'Turns & Leaps',
          code: 'tnl',
          start: '6:00',
          end: '6:30'
        }],
        f: [],
        s: [{
          title: 'Toddler & Me',
          start: '10:30',
          end: '11:00am'
        }]
      }
    }]
  }
}
