import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/teams',
    redirect: { name: 'Home' },
    name: 'Teams',
    component: () => import(/* webpackChunkName: "teams" */ '../views/Teams.vue')
  },
  {
    path: '/events',
    redirect: { name: 'Home' },
    name: 'Events',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/recitals',
    redirect: { name: 'Home' },
    name: 'Recitals',
    component: () => import(/* webpackChunkName: "recitals" */ '../views/Recitals.vue')
  },
  {
    path: '/register',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue')
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue')
  },
  {
    path: '/waiver',
    redirect: { name: 'Home' },
    name: 'Waiver',
    component: () => import(/* webpackChunkName: "waiver" */ '../views/Waiver.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
