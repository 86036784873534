
import Vue from 'vue'

export default Vue.extend({
  data: () => {
    return {
      dialog: false
    }
  },

  computed: {
    inherited () {
      const { src, popUpSrc, ...rest } = this.$attrs
      return rest
    },

    popUpSrc () {
      return this.$attrs.popUpSrc || this.$attrs.src
    }
  }
})
