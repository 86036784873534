
import Vue from 'vue'
import { mdiMenu, mdiHome } from '@mdi/js'

function flexSize (size: number, variable: number, min: number, max: number) {
  const diff = max - min;
  return Math.max((size - variable) / size * diff + min, min);
}

const SCROLL_MAX = 70;
const ICON_SIZE = { min: 3.5, max: 8 };
const TITLE_LEFT = { min: 38, max: 69 };
const TITLE_TOP = { min: 20, max: 54 };
const TITLE_LINE_HEIGHT = { min: 17, max: 28 };
const BTN_HEIGHT = { min: 25, max: 36 };

export default Vue.extend({
  name: 'App',

  data: () => ({
    iconSize: ICON_SIZE.max,
    titleLeft: TITLE_LEFT.max,
    titleTop: TITLE_TOP.max,
    titleLineHeight: TITLE_LINE_HEIGHT.max,
    btnHeight: BTN_HEIGHT.max,
    drawer: false,
    page: '/',

    nav: [{
      text: 'Calendar & Events',
      to: 'events',
      disable: true
    }, {
      text: 'Schedule & Tuition',
      to: 'schedule'
    }, {
      text: 'Recitals',
      to: 'recitals',
      disable: true
    // }, {
    //   text: 'Teams',
    //   to: 'teams',
    //   disable: true
    }, {
      text: 'Registration',
      to: 'register'
    }, {
      text: 'My Parent Portal',
      href: 'https://dancestudio-pro.com/online/index.php?account_id=17992',
      target: 'dsp_parent_portal'
    }, {
      text: 'About',
      to: 'about',
      hide: true
    }, {
      text: 'Contact Us',
      to: 'contact',
      hide: true
    }],

    get mdi () {
      return {
        menu: mdiMenu,
        home: mdiHome
      }
    },

    get titleStyle () {
      return `
          line-height: ${this.titleLineHeight}px;
          top: ${this.titleTop}px;
          left: ${this.titleLeft}px;
        `;
    },

    get btnStyle () {
      // let btnCtn = this.$ref.btnContainer;
      return this.btnHeight === BTN_HEIGHT.max ? '' : `
        height: ${this.btnHeight}px;
      `;
    }
  }),

  methods: {
    onScroll ({ target: { scrollingElement: se } }: {target: HTMLDocument}) {
      const wrapped = this.$refs.btnContainer && (this.$refs.btnContainer as HTMLElement).offsetHeight > BTN_HEIGHT.max;
      this.iconSize = flexSize(SCROLL_MAX, (se && se.scrollTop) || 0, ICON_SIZE.min, ICON_SIZE.max);
      this.titleLeft = flexSize(SCROLL_MAX, (se && se.scrollTop) || 0, TITLE_LEFT.min, TITLE_LEFT.max);
      this.titleTop = flexSize(SCROLL_MAX, (se && se.scrollTop) || 0, TITLE_TOP.min, TITLE_TOP.max);
      this.titleLineHeight = flexSize(SCROLL_MAX, (se && se.scrollTop) || 0, TITLE_LINE_HEIGHT.min, TITLE_LINE_HEIGHT.max);
      this.btnHeight = wrapped ? flexSize(SCROLL_MAX, (se && se.scrollTop) || 0, BTN_HEIGHT.min, BTN_HEIGHT.max) : BTN_HEIGHT.max;
    },

    goHome () {
      this.$router.push({ path: '/' });
    }
  }
})
